import { Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'

import { UploadInput } from '@components/Form'

export const QualificationStatementDocs: FC<{ stepIndex: number; isModification: boolean }> = ({
    stepIndex,
    isModification,
}) => {
    const [form] = Form.useForm()
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        isModification &&
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex))

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Документы к заявке"
            allowEdit={allowEdit}
            saveAfterEdit
            disabledHeaderStepCounter={true}
        >
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="attachments"
                    uneditable={isEdit}
                    title="Для подачи заявки прикрепите документы, подтверждающие соответствие требованиям к квалифицированным инвесторам. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                />
            </div>
        </Step>
    )
}
