import React, { Dispatch, SetStateAction } from 'react'

const dummy = () => {
    return
}

export const EmissionCardPageContext = React.createContext<{
    disabledActionButtons: boolean
    setDisabledActionButtons: Dispatch<SetStateAction<boolean>>
}>({
    disabledActionButtons: false,
    setDisabledActionButtons: dummy,
})