import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import moment, { Moment } from 'moment'

import {
    DfaStatusEnum,
    IDfaFront,
    IssueAvailableInvestorsType,
    preStatuses,
} from '@dltru/dfa-models'
import { SupplyInvestedType } from '@dltru/dfa-models/src/models/dfa/countDfa'
import {
    Alert,
    Box,
    Col,
    DecisionBlock,
    InfoCardsDfa,
    Row,
    VIEW_DATE_FORMAT,
    prepareInitialDfa,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { putDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { paymentSchedulerSelector } from '@store/paymentsScheduler/selectors'

import { RedeemAlert } from '@pages/DFAEmission/DFAEmissionCardPage/components/Alerts'
import { AlertApproval } from '@pages/DFAEmission/DFAEmissionCardPage/components/Alerts/AlertApproval'

import { DFADetailsStepper } from '@components/DFADetails'
import { Links } from '@components/EmissionDecisionCard/components'
import { RelatedTariff } from '@components/RelatedTariff/RelatedTariff'

import { CouponPaymentAlert } from './Alerts/CouponePaymentAlert'

dayjs.extend(customParseFormat)

export const AboutDfa: FC = () => {
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isForRepaymentDirect = useSelector(dfaDetailsSelector.selectIsForDirectRepayment)
    const isForRepaymentPlatform = useSelector(dfaDetailsSelector.selectIsForPlatformRepayment)
    const showDecisionBlock = dfaDetails.status && !preStatuses.includes(dfaDetails.status)
    const couponPaymentCalendar = useSelector(paymentSchedulerSelector.getDates)
    const currentUser = useSelector(authSelector.selectUserUid)
    const isEmitter = currentUser === dfaDetails.emitter_id
    const isShowAlert =
        dfaDetails.status === DfaStatusEnum.collection_started &&
        dfaDetails.issue_available_investors_type === IssueAvailableInvestorsType.qualified

    const saveDfa = (value: IDfaFront) => {
        value.issue_end_date = (value.issue_end_date as unknown as Moment)?.valueOf()
        value.issue_start_date = (value.issue_start_date as unknown as Moment)?.valueOf()
        value.redeem_date = (value.redeem_date as unknown as Moment)?.valueOf()
        value.price_per_dfa = parseFloat(value.price_per_dfa as unknown as string)
        if (value.coupon_payment_amount) {
            value.coupon_payment_amount = parseFloat(
                value.coupon_payment_amount as unknown as string,
            )
        }
        if (value.supply_invested_type === SupplyInvestedType.diapason) {
            value.max_supply_invested = parseFloat(value.max_supply_invested as unknown as string)
            value.min_supply_invested = parseFloat(value.min_supply_invested as unknown as string)
        } else {
            value.max_supply_invested = parseFloat(value.max_supply_invested as unknown as string)
        }
        value.coupon_payment_calendar = couponPaymentCalendar
        reduxDispatch(putDfa({ ...dfaDetails, ...value }))
    }

    const couponPaymentAlarmDate =
        isEmitter &&
        dfaDetails.status === DfaStatusEnum.emitment_success &&
        couponPaymentCalendar.find((item) => {
            const date = dayjs(item.payment_date, VIEW_DATE_FORMAT)
            const diff = date.diff(dayjs().startOf('day'), 'days', true)
            if (diff <= 5 && diff >= 0) {
                return date
            }
            return null
        })

    return (
        <>
            <AlertApproval />
            <Box padding={[0, 24, 24, 24]} margin={[0, 0, 8, 0]} className="tablebox">
                <Row gutter={[16, 24]} style={{ marginTop: 16 }}>
                    {isForRepaymentPlatform && (
                        <Col span={24}>
                            <RedeemAlert />
                        </Col>
                    )}
                    {couponPaymentAlarmDate && (
                        <Col span={24}>
                            <CouponPaymentAlert date={couponPaymentAlarmDate.payment_date} />
                        </Col>
                    )}
                    {dfaDetails.status === DfaStatusEnum.mature && (
                        <Col span={24}>
                            <Alert
                                showIcon
                                type="warning"
                                description="ЦФА находятся в процессе погашения, исполнение заявок приостановлено."
                            />
                        </Col>
                    )}
                    {isForRepaymentDirect && (
                        <Col span={24}>
                            <Alert
                                message="Погасите выпуск ЦФА"
                                description={`Проведите погашение выпуска до ${moment(
                                    dfaDetails.redeem_date,
                                ).format('DD.MM.YYYY HH:mm')}.`}
                                type="info"
                                showIcon
                            />
                        </Col>
                    )}
                    {showDecisionBlock && (
                        <Col span={24}>
                            <DecisionBlock dfa={dfaDetails} LinksComponent={Links} />
                        </Col>
                    )}
                    <InfoCardsDfa data={dfaDetails.emitter_info} />
                </Row>
            </Box>
            {isShowAlert && (
                <Box margin={[0, 0, 8, 0]}>
                    <Alert
                        showIcon
                        type="warning"
                        description="ЦФА доступны для приобретения только квалифицированным инвесторам"
                    />
                </Box>
            )}
            <RelatedTariff dfaId={dfaDetails.id} />
            {dfaDetails.id && (
                <DFADetailsStepper
                    initialValues={prepareInitialDfa(dfaDetails)}
                    initialStep={10}
                    saveAfterEdit
                    onStepperFinish={saveDfa}
                />
            )}
        </>
    )
}
