/* eslint-disable unused-imports/no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IInvestorRegistryApplicationPost,
    IInvestorRegistryApplicationPut,
    InvestorRegistryApplicationDocumentItem,
} from '@dltru/dfa-models'

import { IQualForm } from './type'

export const qualFormToRequest = (
    data: Partial<IQualForm>,
): IInvestorRegistryApplicationPost | null => {
    if (!data.user_name || !data.user_uuid) {
        return null
    }

    const docs: InvestorRegistryApplicationDocumentItem[] =
        data.attachments?.map((item) => ({
            link_uuid: item.uid || '',
            name: item.originFileObj?.name || '',
        })) || []

    const request: IInvestorRegistryApplicationPost = {
        user_name: data.user_name,
        user_uuid: data.user_uuid,
        initiator_type: 'investor',
        application_type: 'inclusion',
        reason_description: '',
        documents: docs, // { items: docs }, // TODO: BE формат из свагера не соответсвует
    }
    return request
}

export const qualFormToPutRequest = (
    data: Partial<IQualForm>,
): IInvestorRegistryApplicationPut | null => {
    if (!data.application_uuid || !data.user_uuid) {
        return null
    }

    const docs: InvestorRegistryApplicationDocumentItem[] =
        data.attachments?.map((item) => ({
            link_uuid: item.uid || '',
            name: item.originFileObj?.name || '',
        })) || []

    const request: IInvestorRegistryApplicationPut = {
        application_uuid: data.application_uuid,
        operator_uuid: data.user_uuid,
        operator_role: 'operator',
        status: 'operator_approval',
        comment: '',
        documents: docs, //{ items: docs }, // TODO: BE формат из свагера не соответсвует
    }
    return request
}
