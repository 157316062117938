import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getTagProps } from '@resources/dfaState'

import { DfaStatusEnum, preStatuses } from '@dltru/dfa-models'
import { Box, PageHeader, Tabs, Tag } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { getDfaDealsById } from '@store/dfa/deals'
import { dropDfaDetails, getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'
import { AboutDfa } from '@pages/DFAEmission/DFAEmissionCardPage/components/AboutDfa'

import { UseTabsAndHash } from '../../../hooks/useTabsAndHash'

import { CouponPaymentTable } from './components/CouponPaymentTable/CouponPaymentTable'
import DealsTable from './components/DealsTable'
import { EarlyRepaymentTable } from './components/EarlyRepaymentTable/EarlyRepaymentTable'
import { Footer } from './components/Footer'
import { IndividualOffersTable } from './components/IndividualOffersTable'
import MyOrdersTable from './components/MyOrdersTable'
import OrdersDfaTable from './components/OrdersDfaTable'
import { OwnersTable } from './components/OwnerTables/OwnersTable'
import './style.less'

const { TabPane } = Tabs

export enum TabName {
    about = 'about-dfa',
    my_orders = 'my-orders',
    orders_dfa = 'orders-dfa',
    individual_offers = 'individual-offers',
    coupon_payment = 'coupon-payment',
    deals = 'deals',
    owners = 'owners',
    early_repayment = 'early_repayment',
}

const tabNames = Object.values(TabName)
const defaultTab = TabName.about

const EmissionCardPage: React.FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab, { replace: true })
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const { dfa: urlDfaParam } = useParams()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const uuid = useSelector(authSelector.selectUserUid)

    const isShowApplication = useSelector(dfaDetailsSelector.selectIsShowApplication)
    const isShowDeals = useSelector(dfaDetailsSelector.selectIsShowDeals)
    const isShowTransfer = useSelector(dfaDetailsSelector.selectIsShowTransfer)
    const isShowOwners = useSelector(dfaDetailsSelector.selectIsShowOwners)
    const isShowCoupon = useSelector(dfaDetailsSelector.selectIsShowCoupon)

    const tagProps = getTagProps(dfaDetails.status)
    const isShowEarlyRepayment = useSelector(dfaDetailsSelector.selectEarlyRepayment)

    const [disabledActionButtons, setDisabledActionButtons] = useState(false)

    //const { startPending, stopPending } = timerCallback()

    useEffect(() => {
        reduxDispatch(dropDfaDetails())

        if (urlDfaParam) {
            // startPending(() => {
            reduxDispatch(getDfaById({ dfaId: Number(urlDfaParam), withBalance: true }))
            reduxDispatch(getDfaDealsById(Number(urlDfaParam)))
            // })
        }

        return () => {
            // stopPending()
            // reduxDispatch(dropDfaDetails())
        }
    }, [urlDfaParam])

    useEffect(() => {
        if (dfaDetails.status && activeTab !== TabName.about) {
            onChangeTab(TabName.about)
        }
    }, [dfaDetails.status])

    const dfaShortInfo = {
        title: dfaDetails.title,
        id: urlDfaParam,
        ticker_symbol: dfaDetails.ticker_symbol,
        emitter_id: dfaDetails.emitter_id,
        emitter_info: dfaDetails.emitter_info,
    }

    const isProcessingDfa = preStatuses.includes(dfaDetails.status)

    return (
        <EmissionCardPageContext.Provider
            value={{ disabledActionButtons, setDisabledActionButtons }}
        >
            <Box padding={16}>
                <PageHeader
                    title={dfaDetails.ticker_symbol}
                    subTitle={dfaDetails.title}
                    onBack={() => navigate(-1)}
                    tags={
                        tagProps?.color ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : <></>
                    }
                />
            </Box>
            {isProcessingDfa ? (
                <AboutDfa />
            ) : (
                <Tabs onChange={onChangeTab} activeKey={activeTab}>
                    <TabPane tab="Условия выпуска" key={TabName.about}>
                        <AboutDfa />
                    </TabPane>
                    {isShowApplication && dfaDetails.emitter_id === uuid && (
                        <TabPane tab="Заявки " key={TabName.my_orders}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <MyOrdersTable />
                            </Box>
                        </TabPane>
                    )}
                    {dfaDetails.status === DfaStatusEnum.emitment_success && (
                        <TabPane tab="Вторичное обращение" key={TabName.orders_dfa}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <OrdersDfaTable dfa={dfaDetails.id} />
                            </Box>
                        </TabPane>
                    )}
                    {isShowTransfer && (
                        <TabPane tab="Оферты" key={TabName.individual_offers}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <IndividualOffersTable params={{ asset_id: dfaDetails.id }} />
                            </Box>
                        </TabPane>
                    )}
                    {isShowCoupon && (
                        <TabPane tab="Купонный доход" key={TabName.coupon_payment}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <CouponPaymentTable dfa={dfaDetails} />
                            </Box>
                        </TabPane>
                    )}
                    {isShowDeals && (
                        <TabPane tab="Мои сделки " key={TabName.deals}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <DealsTable dfaInfo={dfaShortInfo} uuid={uuid} />
                            </Box>
                        </TabPane>
                    )}
                    {isShowOwners && dfaDetails.emitter_id === uuid && (
                        <TabPane tab="Владельцы " key={TabName.owners}>
                            <Box padding={[0, 24, 24, 24]} className="tablebox">
                                <OwnersTable dfa={dfaDetails} />
                            </Box>
                        </TabPane>
                    )}
                    {isShowEarlyRepayment && (
                        <TabPane tab="Досрочное погашение" key={TabName.early_repayment}>
                            {dfaDetails && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <EarlyRepaymentTable dfaInfo={dfaDetails} userUuid={uuid} />
                                </Box>
                            )}
                        </TabPane>
                    )}
                </Tabs>
            )}
            <Footer />
        </EmissionCardPageContext.Provider>
    )
}

export default EmissionCardPage
