import {
    Box,
    Card,
    CardContent,
    CardField,
    CardHeader,
    CopyButton,
    Divider,
    PageHeader,
    StepperC,
} from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { profileSelector } from '@store/profiles/details/selectors'
import { qualifyStatementRequestQualify } from '@store/qualifyStatement'
import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { InformationAboutUser } from '@pages/ClientCard/MyProfile/components/QualificationTab/components/InformationAboutUser'
import { Status } from '@pages/ClientCard/MyProfile/components/QualificationTab/components/Status'

import { QualificationStatementDocs } from './components/QualificationStatementDocs/QualificationStatementDocs'
import { IQualForm } from './type'
import { qualFormToRequest } from './utils'

export const CreateQualificationStatement: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const profileAgent = useSelector(profileSelector.selectProfileAgent)
    const profile = useSelector(profileSelector.selectProfile)
    const profileFullName = useSelector(profileSelector.userFullName)
    const profileagentFullName = useSelector(profileSelector.agentFullName)
    const type = useSelector(profileSelector.selectProfileTypes)
    const qualifyStatement = useSelector(qualifyStatementSelector.selectCurrentQualifyStatement)
    const userID = useSelector(authSelector.selectUserUid)

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявление о признании квалифицированным инвестором"
                    onBack={() => window.history.back()}
                    tags={qualifyStatement ? <Status status={qualifyStatement?.status} /> : <></>}
                />
            </Box>

            <Card className="boxContent">
                <CardHeader title="Основная информация" />
                <CardField
                    title="Дата подачи заявки"
                    value={qualifyStatement?.created_at}
                    padding={16}
                />
                <CardField
                    title="Заявитель"
                    value={
                        <>
                            <span>{profileFullName}</span>&ensp; ID&ensp; {profile?.user_uuid}
                            {profile?.user_uuid && <CopyButton text={profile.user_uuid} />}
                        </>
                    }
                    padding={16}
                />
                <Divider />
                <CardContent>
                    <InformationAboutUser
                        profileAgent={profileAgent}
                        profile={profile}
                        profileFullName={profileFullName}
                        profileagentFullName={profileagentFullName}
                        profileType={type ? type[0] : undefined}
                    />
                </CardContent>
            </Card>

            <StepperC
                initialValues={{}}
                initialStep={0}
                onStepperFinish={(value) => {
                    const formValue = {
                        ...(value as IQualForm),
                        user_uuid: userID || '',
                        user_name: profileFullName || '',
                    }
                    const reqValue = qualFormToRequest(formValue)
                    if (reqValue) {
                        reduxDispatch(qualifyStatementRequestQualify({ data: reqValue, navigate }))
                    }
                }}
            >
                <QualificationStatementDocs stepIndex={0} isModification={true} />
            </StepperC>

            {/*<Footer isStatementComplete={isShowFooter} data={initialValues} />*/}
        </>
    )
}
